<template>
  <AccountUnverified v-if="!user.email_verified_at" />
  <div class="Api" v-else-if="user.subscription_plan.api_access">
    <div class="mb-3">
      <h4>API Implementation</h4>
      <br>
      <p>Creating a token is necessary for being able to collect a certain monitor data.</p>
      <p>To get a list of all monitors ids, use the token on a GET request to the route: https://api.pulsetic.com/api/public/monitors. You need to add the key "Authorization" with the value being the token in the request header.</p>
      <br>
      <h4>There are 3 routes that can be used to get monitors data (as shown the example):</h4>
      <ul>
        <li>Snapshots: <br>https://api.pulsetic.com/api/public/monitor_id/snapshots?end_dt=2022-09-12 16:05:03&start_dt=2021-01-03 14:00:00</li>
        <li>Checks: <br>https://api.pulsetic.com/api/public/monitor_id/checks?end_dt=2022-09-12 16:05:03&start_dt=2021-01-03 14:00:00</li>
        <li>Events: <br>https://api.pulsetic.com/api/public/monitor_id/events?end_dt=2022-09-12 16:05:03&start_dt=2021-01-03 14:00:00</li>
        <br>
        <h4>For the checks route you can also specify the following:</h4>
        <ul>
          <li><strong>&nodes[]=frankfurt&nodes[]=new_york</strong> and so on, to get the checks related to the specified node/nodes</li>
          <li><strong>&response_codes[]=200&response_codes[]=500</strong> and so on, to get the checks related to the specified response code/codes</li>
        </ul>
        <br>
        <h4>For the events route you can also specify the following:</h4>
        <ul>
          <li><strong>&monitor_event_type=Online</strong> to get only the online events</li>
          <li><strong>&monitor_event_type=Offline</strong> to get only the offline events</li>
        </ul>

      </ul>

      <h4>There is a route for listing and adding monitors (as shown the example):</h4>
      <ul>
        <li>
          https://api.pulsetic.com/api/public/monitors
        </li>
      </ul>

      <h4>There is a route for updating and deleting monitors (as shown the example):</h4>
      <ul>
        <li>
          https://api.pulsetic.com/api/public/monitors/{id}
        </li>
      </ul>

      <h4>There is a route for retrieving the downtime of a monitor until now by number of seconds
        (if the <b>seconds</b> parameter is not passed the default will be 3600):</h4>
      <ul>
        <li>
          https://api.pulsetic.com/api/public/monitors/{id}/downtime?seconds={seconds}
        </li>
      </ul>

      <h4>There is a route for that can be used to get status pages, create and update a status page (as shown the example):</h4>
      <ul>
        <li>https://api.pulsetic.com/api/public/status-page</li>
      </ul>
      <h5>start_dt / end_dt represent the time interval limits in which the monitor's data was created</h5>
      <p>Example for testing: <br>curl --location --request GET "https://api.pulsetic.com/api/public/4172/checks?end_dt=2022-09-12+16:05:03&start_dt=2021-01-03+14:00:00" --header "Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d"</p>
      <br>

      <p>
        Example for listing monitors: <br>curl --location "https://api.pulsetic.com/api/public/monitors"
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'
        --header 'Content-Type: application/json'
      </p>
      <br>

      <p>Example for adding monitors: <br>curl --location "https://api.pulsetic.com/api/public/monitors" --header "Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d" --header "Content-Type: application/json" --data '{
        "urls": ["https://w5.com"]
        }'
      </p>
      <br>

      <p>Example for updating monitor: <br>curl --location --request PUT "https://api.pulsetic.com/api/public/monitors/1"
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'
        --header 'Content-Type: application/json'
        --data '{
        "url": "https://facebook.com",
        "uptime_check_frequency": "600",
        "name": "Facebook Page",
        "request": {
        "type": "http",
        "body_type": "form_params",
        "body_form_params": [
        {
        "name": "param1",
        "value": "param1value"
        },
        {
        "name": "param2",
        "value": "param2value"
        }
        ],
        "method": "post",
        "headers":[
        {
        "name": "Accept",
        "value": "application/json"
        }
        ]
        },
        "response": {
        "body": "some text",
        "headers": [
        {"header1": "header1value"},
        {"header2": "header2value"}
        ],
        "isNegative": false
        }'
      </p>
      <br>

      <p>
        Example for deleting monitor: <br>curl --location --request DELETE "https://api.pulsetic.com/api/public/monitors"
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'
        --header 'Content-Type: application/json'
      </p>
      <br>

      <p>
        Example for retrieving the downtime:<br>curl --location
        "https://api.pulsetic.com/api/public/monitors/4/downtime?seconds=3600"
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'
        --header 'Content-Type: application/json'
      </p>
      <br>

      <p>Example for adding status page: <br>curl --location --request POST 'https://api.pulsetic.com/api/public/status-page'
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'
        --header 'Content-Type: application/json'
        --data '{
        "title": "My Second Status Page",
        "domain": "mydomain.com",
        "meta_title": "My Title",
        "site_key": "",
        "secret_key": "",
        "uptime_threshold": 0.9,
        "remove_branding": false,
        "subscribe_to_updates": false,
        "private": false,
        "password": "mypass",
        "uptime_percentage_style": false,
        "show_location_tooltip": true,
        "monitors": [1, 2]
        }'</p>
      <br>
      <p>Example for updating status page: <br>curl --location --request PUT 'https://api.pulsetic.com/api/public/status-page/13'
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'
        --header 'Content-Type: application/json'
        --data '{
        "title": "My Status Page Updated",
        "domain": "mydomain.com",
        "meta_title": "My Title",
        "site_key": "",
        "secret_key": "",
        "uptime_threshold": 0.9,
        "remove_branding": false,
        "subscribe_to_updates": false,
        "private": false,
        "password": "mypass",
        "uptime_percentage_style": true,
        "show_location_tooltip": true,
        "monitors": [1,2]
        }'</p>
      <br>
      <p>Example for retrieving all status pages: <br>curl --location 'https://api.pulsetic.com/api/public/status-page'
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'</p>
      <br>

      <p>Example for deleting a status page: <br>curl --location --request DELETE 'https://api.pulsetic.com/api/public/status-page/1'
        --header 'Authorization: 1fcfe8005184c6f5fd86a7c36c91470f4d05cc8d'</p>
    </div>

    <div class="mb-3">
      Or you can simply copy the content of <a :href=apiUrl target="_blank" style="color: #4e67eb">this file</a>
      and paste it here: <a href="https://editor.swagger.io/" target="_blank" style="color: #4e67eb">https://editor.swagger.io/</a>
    </div>

    <form v-if="!token" @submit.prevent="storeForm" style="display: flex">
      <datepicker v-model="selectedDate" input-class="date_style" placeholder="Token expiration date" clear-button typeable></datepicker>
      <base-button v-if="true" type="submit"
                   color="primary"
                   class="add"
                   :disabled="addTokenButton"
                   :loading="formLoading">Add Token</base-button>
    </form>
    <h5 v-if="!token" style="margin-top: 3px">If there is no expiration date specified, the token will be permanent</h5>
    <div v-if="token" style="display: flex">
      <input
        type="text"
        size="38"
        v-on:focus="$event.target.select()"
        :value="token"
        readonly
        ref="token">
      <base-button
        v-if="!copied"
        @click="copy"
        color="primary"
        class="BaseButton"
        style="margin-top: -4px; height: 30px; margin-left: 10px"
        >Copy</base-button>
      <base-button
        v-if="copied"
        @click="copy"
        color="success"
        class="BaseButton"
        style="margin-top: -4px; height: 30px; margin-left: 10px; background-color: grey"
      >Copied</base-button>
      <base-button
             @click="deleteForm"
             color="danger"
             class="BaseButton"
             style="margin-top: -4px; height: 30px; margin-left: 10px"
             :loading="formLoading">Delete Token</base-button>

    </div>
    <h5 v-if="token" style="margin-top: 3px">Expires at: {{token_expire_date ? token_expire_date : 'never'}}</h5>
    <base-alert v-if="tokenError" class="mt-4"
                type="error">We could not generate your token.</base-alert>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="API is available only for Pro and Business customers."/>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Datepicker from 'vuejs-datepicker'
import UpgradeBadge from '@/components/Common/UpgradeBadge'
export default {
  components: {
    Datepicker,
    UpgradeBadge
  },

  data () {
    return {
      formLoading: false,
      tokenError: false,
      selectedDate: null,
      copied: false
    }
  },

  created () {
    this.$store.dispatch('api/loadToken')
  },

  methods: {
    copy () {
      this.$refs.token.focus()
      document.execCommand('copy')
      this.copied = true
    },

    async storeForm () {
      if (!(Object.prototype.toString.call(this.selectedDate) === '[object Date]' || this.selectedDate == null)) {
        return false
      }
      this.tokenError = false
      this.formLoading = true

      try {
        await this.$store.dispatch('api/storeToken', this.selectedDate)
        this.showSuccessMessage()
      } catch (error) {
        console.error(error)
        this.tokenError = true
      }
      this.formLoading = false
    },

    async deleteForm () {
      this.tokenError = false
      this.formLoading = true

      try {
        await this.$store.dispatch('api/deleteToken')
        this.showDeleteMessage()
      } catch (error) {
        console.error(error)
        this.tokenError = true
      }
      this.formLoading = false
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'The token has been generated successfully',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    },

    showDeleteMessage () {
      Swal.fire({
        title: 'Success',
        text: 'The token has been deleted successfully',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    }
  },
  computed: {
    apiUrl () {
      return process.env.VUE_APP_BASE_URL + 'docs/api.yaml'
    },

    addTokenButton () {
      if (this.selectedDate == null) {
        return false
      }

      return !(Object.prototype.toString.call(this.selectedDate) === '[object Date]' || this.selectedDate == null)
    },

    ...mapState('authentication', ['user']),
    ...mapState('api', ['token', 'token_expire_date'])
  }
}
</script>

<style scoped lang="scss">
.Api {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
}
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media (max-width: 767px) {
    margin: 24px 0;
    min-height: unset !important;
  }

}
.vdp-datepicker {
  &::v-deep .date_style {
    padding: 0 16px;
    height: 44px;

    border: 1px solid transparent;
    background: #FFFFFF;
    border-radius: 6px;

    outline: none;

    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s;

    &:hover,
    &:focus {
      box-shadow: 0 3px 5px rgba(101, 81, 224, 0.15);
      border-color: map-get($colors, purple-1);
    }

    &::placeholder {
      color: #a4a3a7;
      font-weight: 400;
    }
  }
}
.add {
  margin: 0 0 0 10px;
}
</style>
